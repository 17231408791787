import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  RadioGroup,
  Radio,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  Dot,
  MessageCircle,
  MoreVertical,
  Share2,
  ThumbsUp,
} from "lucide-react";
import LikeEmojiGroup from "@/components/common/likeEmojiGroup";
import AvatarGroups from "@/components/common/avatarGroups";
import { MdPlayArrow } from "react-icons/md";
import { Progress } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { groupMarkPoll, userMarkPoll } from "@/api/feed/groups/post";
import { randomColors } from "@/utilities/commonFunctions";
import { ReactionPanel } from "@/components/common/reactionPanel";
import CommentSlider from "@/components/postCards/commentSlider/commentSlider";
import { getTimeAgo } from "@/utilities/utilityFunction";
import PostOption from "@/components/common/postOption";
import { useDisclosure } from "@chakra-ui/react";

const breakLines = (text) => {
  const lines = text?.split("\n");
  return lines?.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}{" "}
      {/* Add <br /> only if it's not the last line */}
    </React.Fragment>
  ));
};

const PollFeedCard = ({
  name,
  uid,
  profilePic,
  title,
  options,
  reactionCount,
  commentCount,
  createdAt,
  media,
  triggeredFrom,
  postId,
  followingCount,
  reactionCountDetail,
  topComments,
  allowComment,
  userReaction,
}) => {
  console.log("in poll", options);

  const [isExpanded, setIsExpanded] = useState(false);

  const {
    isOpen: isOpenComment,
    onToggle: onToggleComment,
    onClose: onClose,
  } = useDisclosure();

  const [value, setValue] = useState();

  const isTitleSmall = title?.length < 86;
  const charLimit = 400; // Character limit before truncating

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderTitle = () => {
    if (isExpanded) {
      return (
        <>
          {breakLines(title)}
          <Text
            as="span"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleToggleExpand}
          >
            see less
          </Text>
        </>
      );
    } else if (title?.length <= charLimit) {
      return breakLines(title);
    } else {
      return (
        <>
          {breakLines(title?.slice(0, charLimit))}...
          <Text
            as="span"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleToggleExpand}
          >
            see more
          </Text>
        </>
      );
    }
  };

  useEffect(() => {
    for (var i = 0; i < options?.length; i++) {
      let uidIndex = options[i].uid.findIndex((it) => it._id == uid);
      if (uidIndex > -1) {
        setValue(i);
        break;
      }
    }
  }, []);

  const mutation = useMutation({
    mutationFn: (payload) =>
      triggeredFrom == "group"
        ? groupMarkPoll(payload.postId, payload.option, payload.uid)
        : userMarkPoll(payload.postId, payload.option, payload.uid),
    onMutate: (variables) => {
      return console.log("mutation is happening");
    },
    onError: (error, variables, context) =>
      toast.error(`${error?.response?.data.error?.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      }),
    onSuccess: (res, variables, context) => {
      toast.success("Poll Save Successfull", {
        position: toast.POSITION.TOP_RIGHT,
      });
      queryClient.invalidateQueries("getAllPosts");
      setText();
      setSelectedFiles([]);
      setOptions([]);
      setSelectedComponent(null);
      onClose();
    },
    onSettled: (data, error, variables, context) => {},
  });

  const handlePollClick = (poll) => {
    setValue(poll);
    mutation.mutate({ postId: postId, option: poll?._id, uid: uid });
    //add api call to mark a poll
  };

  return (
    <>
      <Box bg="white.900" w="full" mx="auto" mt="1">
        <HStack
          align="center"
          justifyContent="space-between"
          padding={["3", null, "3"]}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box boxSize="43px">
              <Image
                objectFit="cover"
                width="100%"
                height="100%"
                className="rounded-3xl"
                src={profilePic}
                alt="Profile Image"
              />
            </Box>
            <Box ml="2">
              <Box display="flex" alignItems="center">
                <p
                  style={{
                    fontSize: "15px",
                    color: "#171717",
                    fontWeight: "650",
                    lineHeight: "15px",
                  }}
                >
                  {name}
                </p>
                {triggeredFrom == "group" && (
                  <>
                    <MdPlayArrow />
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#171717",
                        fontWeight: "400",
                      }}
                    >
                      Posted in CAT 2021
                    </p>
                  </>
                )}
              </Box>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginRight: "3px",
                }}
              >
                <p
                  style={{
                    fontSize: "13px",
                    color: "#636363",
                  }}
                >
                  {getTimeAgo(createdAt)}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  viewBox="0 0 24 24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-dot"
                >
                  <circle cx="12.1" cy="12.1" r="1" />
                </svg>
                <img
                  src="/lock.svg"
                  width={12}
                  style={{ marginBottom: "4px" }}
                />
              </div>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap="4">
            <PostOption
              postUserId={uid}
              postId={postId}
              triggeredFrom={triggeredFrom}
              allowComment={allowComment}
            />
          </Box>
        </HStack>
        <Stack padding={["3", null, "0"]} paddingBottom={["3", null, "0"]}>
          <Text
            fontSize={"16px"}
            lineHeight="24px"
            style={{ padding: "0 15px 0px 15px" }}
          >
            {renderTitle()}
          </Text>
          <RadioGroup value={value} width="100%">
            <Stack>
              {options?.map((option, index) => (
                <Box key={index} px="6" py="4">
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    pb="2"
                  >
                    <AvatarGroups data={option.uid} size={3} />
                  </Flex>
                  <Flex alignItems="center" position="relative">
                    <Radio
                      size="lg"
                      name="1"
                      colorScheme="black"
                      value={index}
                      _active={{ bg: "black" }}
                      onClick={() => handlePollClick(option)}
                      position="absolute"
                      left="10px"
                      borderColor="gray"
                      zIndex="1"
                    />
                    <Text position="absolute" left="40px" zIndex="1">
                      {option.title}
                    </Text>
                    <Progress
                      w="100%"
                      colorScheme={"cyan"}
                      isAnimated={"true"}
                      style={{ borderRadius: "10px" }}
                      value={Number(option.votes) * 10}
                      backgroundColor={"white"}
                      border={"0.5px solid gray"}
                      height="50px" // Adjust the height as needed
                      // pl="40px" // Adjust padding to make space for the radio button
                    />
                  </Flex>
                </Box>
              ))}
            </Stack>
          </RadioGroup>

          {/* <RadioGroup
            // onChange={(value) => handlePollClick(value)}
            value={value}
            width="100%"
          >
            <Stack>
              {options?.map((option, index) => {
                return (
                  <>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      px="6"
                      pb="2"
                      pt="4"
                    >
                      <Radio
                        size="lg"
                        name="1"
                        colorScheme="red"
                        value={index}
                        _active={{ bg: "red" }}
                        onClick={() => handlePollClick(option)}
                      >
                        <Text>{option.title}</Text>
                      </Radio>
                      <AvatarGroups data={option.uid} size={3} />
                    </Flex>
                    <Box px="6">
                      <Progress
                        w="100%"
                        colorScheme={randomColors(["green", "red", "yellow"])}
                        rounded="full"
                        value={Number(option.votes) * 10}
                      />
                    </Box>
                  </>
                );
              })}
            </Stack>
          </RadioGroup> */}
          <HStack
            align="center"
            fontWeight="400"
            fontSize="14px"
            padding="5px 15px 5px 15px"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <LikeEmojiGroup
                userReaction={userReaction}
                reactionCountDetail={reactionCountDetail}
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#455564",
                  paddingLeft: "5px",
                }}
              >
                {reactionCount}
              </span>
            </Box>
            <Box display="flex" alignItems="center">
              <Box pl="5">
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#455564",
                  }}
                >
                  {commentCount}
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#8D96A5",
                    paddingLeft: "5px",
                  }}
                >
                  comments
                </span>
              </Box>
              <Dot color="#8D96A5" />
              <Box>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#455564",
                  }}
                >
                  53
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#8D96A5",
                    paddingLeft: "5px",
                  }}
                >
                  following
                </span>
              </Box>
            </Box>
          </HStack>
        </Stack>
      </Box>
    </>
  );
};

export default PollFeedCard;
