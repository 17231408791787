import request from "@/api/request";

export const acceptRequest = async (payload, uid) => {
  //  /feed/v1/group/comment
  try {
    const res = await request({
      url: `/auth/v1/people/friend/accept`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addFriend = async (payload, uid) => {
  //  /feed/v1/group/comment
  try {
    const res = await request({
      url: `/auth/v1/people/friend`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAllFriendsList = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/follow/lists/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const cancleRequest = async (payload, uid) => {
  //  /feed/v1/group/comment

  const { senderId, receiverId } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/friend/cancel/${senderId}/${receiverId}`,
      type: "DELETE",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const declineRequest = async (payload, uid) => {
  //  /feed/v1/group/comment

  const { senderId, receiverId } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/friend/decline`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const unfriend = async (payload, uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/friend/remove`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
