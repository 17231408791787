import {
  Box,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  useDisclosure,
} from "@chakra-ui/react";
import TextFeedCard from "../feedPostCards/textFeedCard";
import ImageFeedCard from "../feedPostCards/imageFeedCard";
import ImageSwiper from "../feedPostCards/imageSwiper";
import CardFeedCard from "../feedPostCards/cardFeedCard";
import PollFeedCard from "../feedPostCards/pollFeedCard";
import VideoFeedCard from "../feedPostCards/videoFeedCard";
import DebateFeedCard from "../feedPostCards/debateFeedCard";
import { useCallback, useState, useEffect } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { throttle } from "lodash";
import React from "react";
import { useInView } from "react-intersection-observer";
import { getAllPost } from "@/api/feed/user/posts";
import { getGroupPosts } from "@/api/feed/groups/post";
import LatestVideosSlider from "../latestVideos/latestVideosSlider";
import FeedCardFooter from "../feedPostCards/FeedCardFooter";
import DocFeedCard from "../feedPostCards/docFeedCard";

const renderFeedCard = (
  item,
  index,
  triggeredFrom,
  isOpenComment,
  onToggleComment
) => {
  const commonProps = {
    name: item?.postTypeId?.createdBy?.name,
    profilePic: item?.postTypeId?.createdBy?.profilePic,
    uid: item?.postTypeId?.createdBy?._id,
    title: item?.postTypeId?.title,
    reactionCount: item?.reactionCount,
    commentCount: item?.commentCount,
    createdAt: item?.postTypeId?.createdAt,
    media: item?.postTypeId?.media,
    topComments: item?.topComments,
    postId: item?.postTypeId?._id,
    userReaction: item?.userReaction,
    followingCount: item?.followedCount,
    reactionCountDetail: item?.reactionCountDetail,
    triggeredFrom: triggeredFrom,
    allowComment: item?.allowComment,
    isOpenComment: isOpenComment,
    onToggleComment: onToggleComment,
  };

  switch (item.postType) {
    case "photo":
    case "memes":
      return item.postTypeId?.media?.length > 1 ? (
        <ImageSwiper key={index} {...commonProps} />
      ) : (
        <ImageFeedCard key={index} {...commonProps} />
      );
    case "video":
      return <VideoFeedCard key={index} {...commonProps} />;
    case "text":
      return <TextFeedCard key={index} {...commonProps} />;
    case "poll":
      return (
        <PollFeedCard
          key={index}
          {...commonProps}
          options={item?.postTypeId?.options}
        />
      );
    case "debate":
      return (
        <DebateFeedCard
          key={index}
          {...commonProps}
          participants={item?.postTypeId?.participants}
        />
      );
    case "doc":
      return <DocFeedCard key={index} {...commonProps} />;
    default:
      return null;
  }
};

export const FeedPostList = ({ triggeredFrom }) => {
  const { _id: uid } = useSelector((state) => state.userData);
  const { _id: groupId } = useSelector((state) => state.groupData);

  const [openCommentPanels, setOpenCommentPanels] = useState({});

  const {
    data,
    error,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [
      triggeredFrom == "group" ? "getGroupPosts" : "getAllPosts",
      triggeredFrom == "group" ? groupId : uid,
    ],
    queryFn: ({ pageParam = 1, limit = 10 }) =>
      triggeredFrom == "group"
        ? getGroupPosts(pageParam, limit, uid, groupId)
        : getAllPost(pageParam, 12, uid),

    getNextPageParam: (lastPage, pages) =>
      lastPage.data.data.pagination.page + 1,
    onError: (error, variables, context) =>
      toast.error(`${error?.response?.data?.error?.message || "Some error"}`, {
        position: toast.POSITION.TOP_RIGHT,
      }),
    onSuccess: (res) => {
      console.log("res for user feed post", res);
    },
  });

  // Implement scrolling detection
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  console.log(data, "data for poll posts");

  const {
    isOpen: isOpenComment,
    onToggle: onToggleComment,
    onClose: onCloseComment,
  } = useDisclosure();

  const handleToggleComment = (postId) => {
    setOpenCommentPanels((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId],
    }));
  };
  return (
    <Box
      style={{
        marginTop: "16px",
        borderTopRightRadius: "9px",
        borderTopRightRadius: "9px",
      }}
    >
      {isLoading ? (
        <>
          <Box padding="6" boxShadow="lg" bg="white">
            <HStack>
              <SkeletonCircle size="10" />
              <Box>
                <SkeletonText
                  mt="4"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="2"
                />
              </Box>
            </HStack>

            <Skeleton mt="4" hegiht="200px">
              <div>contents wrapped</div>
              <div>won't be visible</div>
            </Skeleton>
            <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
          </Box>
        </>
      ) : (
        data?.pages.map((page, pageIndex) => (
          <React.Fragment key={pageIndex}>
            {page.data.data.allPostData.map((item, itemIndex) => (
              <Box
                key={itemIndex}
                style={{
                  backgroundColor: "white",
                  borderRadius: "9px",
                  marginBottom: "16px",
                }}
              >
                {renderFeedCard(
                  item,
                  itemIndex,
                  triggeredFrom,
                  openCommentPanels[item?.postTypeId?._id] || false,
                  (postId) => handleToggleComment(postId)
                )}
                <FeedCardFooter
                  topComments={item?.topComments}
                  postId={item?.postTypeId?._id}
                  userReaction={item?.userReaction}
                  triggeredFrom={triggeredFrom}
                  isOpenComment={
                    openCommentPanels[item?.postTypeId?._id] || false
                  }
                  onToggleComment={handleToggleComment}
                  createdyById={item?.createdBy?._id}
                  allowComment={item?.allowComment}
                />
              </Box>
            ))}
          </React.Fragment>
        ))
      )}
      {isFetching && (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 28,
                  }}
                  spin
                />
              }
            />
          </Box>
        </>
      )}
      <Box ref={ref} sx={{ minHeight: "10em" }}></Box>
      <ToastContainer />
    </Box>
  );
};
