import { getTimeUntil } from "@/utilities/utilityFunction";
import { Card, Box, Stack, Heading, Text, Button, HStack, Flex } from "@chakra-ui/react";
import { before } from "lodash";
import { CalendarDays, ChevronRight, Dot, Scale } from "lucide-react";
import { useRouter, usePathname } from "next/navigation";

const UpComingCard = ({ style, id, width, data }) => {
  const router = useRouter();
  const path = usePathname();
  return (
    <Box mb="4">
      <Card
        // maxW="sm"
        minW="xs"
        h="auto"
        overflow="hidden"
        rounded="xl"
        sx={{
          ":before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            bgSize: "cover",
            bgRepeat: "no-repeat",
            width: "100%",
            height: "100%",
            backgroundImage: data?.hostId?.profilePic ? `url(${data?.hostId?.profilePic})` : `url(/profile.jpeg)`,
            filter: "grayscale(100%)",
          },
        }}
        // bgfilter="grayscale(100%)"
        // filter= 'grayscale(100%)'
        bgPosition="center"
        position="relative"
      >
        <Box h="150px" zIndex="999">
          <Flex p="3" align="center" gap="2">
            <Box w="0.8rem" h="0.8rem" bg="green.500" rounded="full" />
            <Text padding="0" color="#fff" fontSize="16px" fontWeight="500">
              {data?.hostId?.name} 
            </Text>
          </Flex>
        </Box>
        <Flex zIndex="9" align="center" justify="space-between" bg="#000000B8" rounded="lg" m="2" padding="2">
          <Stack align="self-start">
            <Text fontSize="16px" color="#fff" overflow="hidden"  textOverflow="ellipsis">
              {data?.title}
            </Text>
            <HStack fontSize="10px" textAlign="start">
              <span className="text-green-500">{getTimeUntil(data?.eventTypeId?.startDate)}</span>
            </HStack>
          </Stack>
          <Box bg="#9696965C" padding="0" rounded="md">
            {path == "/learn" ? (
              <Button
                onClick={() => router.push("/learn/classList")}
                variant="solid"
                color="#fff"
                backgroundColor="#9696965C"
                _hover={{ color: "#000", backgroundColor: "#fff" }}
              >
                View
              </Button>
            ) : (
              <Button
                onClick={() => router.push(`/learn/class/${id}`)}
                variant="solid"
                color="#fff"
                backgroundColor="#9696965C"
                _hover={{ color: "#000", backgroundColor: "#fff" }}
              >
                View
              </Button>
            )}
          </Box>
        </Flex>
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0%",
            background: "rgba(0,0,0,0.3)",
            zIndex: "1",
            mixBlendMode: "multiply",
            borderRadius: "8px",
          }}
        ></div>
      </Card>
    </Box>
  );
};

export default UpComingCard;
