import VideoPlayer from "@/components/common/videoPlayer";
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";

const VideoModal = ({ isOpen, onClose, media }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent rounded="2xl" overflow="hidden">
        <VideoPlayer videoUrl={media} />
      </ModalContent>
    </Modal>
  );
};

export default VideoModal;
