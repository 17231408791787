import request from "@/api/request";

export const getAllFollowList = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/follow/lists/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addFollow = async (payload, uid) => {
  //  /feed/v1/group/comment
  const { followedBy, followedTo } = payload;

  try {
    const res = await request({
      url: `/auth/v1/people/follow/send/${followedBy}/${followedTo}`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const unfollow = async (payload, uid) => {
  //  /feed/v1/group/comment

  const { followedBy, followedTo } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/follow/remove/${followedBy}/${followedTo}`,
      type: "DELETE",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getFollowers = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/follow/follower/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getFollowing = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/follow/following/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
