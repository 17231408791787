import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";

const SkeletonLoader = ({ type }) => {
  return (
    <Box>
      {type === "friendList" && (
        <>
          {[...Array(2)].map((itm, key) => (
            <Box key={key} py={3} gap={4} display="flex" alignItems="center">
              <Skeleton width="50px" rounded="xl" height="50px" />
              <SkeletonText
                mt="0"
                width="40%"
                noOfLines={2}
                spacing="2"
                skeletonHeight="3"
              />
            </Box>
          ))}
        </>
      )}
      {type === "friendSuggestions" && (
        <>
          <Box display="flex" flexDirection="column" gap={4} width="100%">
            <Skeleton height="100px" width="200px" />
          </Box>
        </>
      )}
      {type === "card" && (
        <>
          <Box width="100%" padding="6" boxShadow="lg" bg="white">
            <Skeleton height="200px" mb="4" />
            <SkeletonText mt="0" noOfLines={3} spacing="4" skeletonHeight="3" />
          </Box>
        </>
      )}
    </Box>
  );
};

export default SkeletonLoader;
