import { Box, useDisclosure } from "@chakra-ui/react";
import SuggestionSection from "../suggestionSection";
import ChallengeCard from "./challengesPostCard/challengeCard";
import ChallengeLivecard from "./challengesPostCard/challengeLivecard";
import ChallengeLeaderbordCard from "./challengesPostCard/challengeLeaderbordCard";
import { useEffect, useState } from "react";
import { useQuery, useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { getGroupChallengeList } from "@/api/feed/groups/challenge";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/navigation";
import ChallengeDetailJoin from "./challengeDetails/challengeDetailJoin";
import { getAllChallenges } from "@/api/feed/user/challenge";

const ChallengeList = ({ triggeredFrom }) => {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedChallengeIndex, setSelectedChallengeIndex] = useState(null);
  const [challengeList, setChallengeList] = useState([]);
  const { _id: uid } = useSelector((state) => state.userData);
  const { _id: groupId } = useSelector((state) => state.groupData);

  const {
    data: challengeListData,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["challengeList", triggeredFrom == "user" ? uid : groupId],
    queryFn: ({ pageParam = 1, limit = 40 }) =>
      triggeredFrom == "user"
        ? getAllChallenges(pageParam, limit, uid)
        : getGroupChallengeList(pageParam, limit, groupId),
    getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
    onError: (error, variables, context) => {},
    // toast.error(`${error?.response?.data?.error?.message || "Some error"}`, {
    //   position: toast.POSITION.TOP_RIGHT,
    // }),
    onSuccess: (res) => {
      console.log("challenge Check", res);
      setChallengeList(res.pages[0]);
    },
  });

  const handleViewChallenge = () => {};

  const handleJoinChallenge = (i) => {
    alert("!!");
    setSelectedChallengeIndex(i);
  };

  useEffect(() => {
    if (selectedChallengeIndex) {
      onOpen();
    }
  }, [selectedChallengeIndex]);
  return (
    <>
      {challengeListData?.pages[0]?.map((challenge, ind) => {
        if (challenge.postTypeId.status === "upcoming") {
          return (
            <Box key={ind}>
              <ChallengeCard
                challengeData={challenge.postTypeId}
                handleJoinChallenge={handleJoinChallenge}
                handleViewChallenge={() => {
                  alert("define view challenge func");
                }}
                indexValue={ind}
              />
            </Box>
          );
        } else if (challenge.postTypeId.status === "live") {
          return (
            <Box key={ind}>
              <ChallengeLivecard challengeData={challenge.postTypeId} />
            </Box>
          );
        }
      })}
      <ChallengeDetailJoin
        isOpen={isOpen}
        onClose={onClose}
        challengeData={challengeList[selectedChallengeIndex]}
      />
      {/* <Box onClick={onOpen}>
        <ChallengeCard challengeData={challenge} />
      </Box> */}
      {/* <ChallengeLeaderbordCard /> */}
    </>
  );
};

export default ChallengeList;
