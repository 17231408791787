import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Image,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  Dot,
  MessageCircle,
  MoreHorizontal,
  MoreVertical,
  ChevronDownIcon,
  Share2,
  ThumbsUp,
} from "lucide-react";
import React, { useState } from "react";
import LikeEmojiGroup from "@/components/common/likeEmojiGroup";
import CommentSlider from "@/components/postCards/commentSlider/commentSlider";
import { MdPlayArrow } from "react-icons/md";
import VideoPlayer from "@/components/common/videoPlayer";
import { ReactionPanel } from "@/components/common/reactionPanel";
import { getTimeAgo } from "@/utilities/utilityFunction";
import PostOption from "@/components/common/postOption";
import { useDisclosure } from "@chakra-ui/react";
import { CommentPanel } from "@/components/common/commentPanel";
import CommentThreadBox from "./commentThreadBox";
import { useRouter } from "next/router";

const VideoFeedCard = ({
  name,
  uid,
  title,
  reactionCount,
  commentCount,
  createdAt,
  media,
  topComments,
  profilePic,
  postId,
  followingCount,
  reactionCountDetail,
  userReaction,
  triggeredFrom,
  allowComment,
  onToggleComment,
  isOpenComment,
}) => {
  const router = useRouter();
  // const [commentSelector, setcommentSelector] = useState("Top Comments");
  // const {
  //   isOpen: isOpen2Comment,
  //   onToggle: onToggleComment,
  //   onClose: onClose,
  // } = useDisclosure();

  // // console.log("IIIIIIIIIIIIIIIIIIIII", media[0], name);

  return (
    <Box bg="#fff" mt="4">
      <HStack
        align="center"
        justifyContent="space-between"
        padding={["3", null, "4"]}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box boxSize="40px">
            <Image
              objectFit="cover"
              width="100%"
              height="100%"
              className="rounded-md"
              src={profilePic}
              alt="Profile Image"
            />
          </Box>
          <Box ml="2">
            <Box display="flex" alignItems="center">
              <p
                style={{
                  fontSize: "14px",
                  color: "#171717",
                  fontWeight: "600",
                }}
              >
                {name}
              </p>{" "}
              <MdPlayArrow />
              {/* <p style={{ fontSize: "14px", color: "#171717", fontWeight: "400" }}>Posted in CAT 2021</p> */}
            </Box>
            <p style={{ fontSize: "14px", color: "#636363" }}>
              {getTimeAgo(createdAt)}
            </p>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="4">
          <PostOption
            postUserId={uid}
            postId={postId}
            title={title}
            triggeredFrom={triggeredFrom}
            allowComment={allowComment}
          />
        </Box>
      </HStack>
      <Stack padding={["3", null, "4"]} paddingBottom={["3", null, "0"]}>
        <Text fontSize="14px" lineHeight="24px">
          <p>{title}</p>
        </Text>
        <Box
          onClick={() => {
            router.push(`/feed/post/${postId}?triggeredFrom=${triggeredFrom}`);
          }}
        >
          <VideoPlayer videoUrl={media ? media[0] : ""} />
        </Box>
        <HStack align="center" fontWeight="400" fontSize="14px" padding="4">
          <Box display="flex" alignItems="center">
            <LikeEmojiGroup
              userReaction={userReaction}
              reactionCountDetail={reactionCountDetail}
            />
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#455564",
                paddingLeft: "5px",
              }}
            >
              {reactionCount}
            </span>
          </Box>
          <Box pl="5">
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#455564" }}
            >
              {commentCount}
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#8D96A5",
                paddingLeft: "5px",
              }}
            >
              comments
            </span>
          </Box>
          <Dot color="#8D96A5" />
          <Box>
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#455564" }}
            >
              53
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#8D96A5",
                paddingLeft: "5px",
              }}
            >
              following
            </span>
          </Box>
        </HStack>
      </Stack>
      {/* <Stack padding={["3", null, "4"]}>
        <Divider />
        <ReactionPanel
          postId={postId}
          userReaction={userReaction}
          isOpenComment={isOpenComment}
          onToggleComment={onToggleComment}
        />
        <Divider />

        <Box px="4" py="1" zIndex="8000" position="static">
          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              _hover={{}}
              _expanded={{ borderColor: "black" }}
            >
              <HStack>
                <span style={{ fontSize: "14px" }}>{commentSelector}</span>{" "}
                <ChevronDownIcon size="14px" />
              </HStack>
            </MenuButton>
            <MenuList zIndex="20">
              <MenuItem
                onClick={() => {
                  setcommentSelector("Top Comments");
                }}
              >
                Top Comments
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setcommentSelector("Later");
                }}
              >
                Latest
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setcommentSelector("2 Month ago");
                }}
              >
                2 Month ago
              </MenuItem>
              <MenuDivider />
            </MenuList>
          </Menu>
        </Box>
        <Box p="0">
          {commentSelector == "Top Comments" && (
            <CommentSlider
              topComments={topComments}
              postId={postId}
              isOpenComment={true}
            />
          )}
          {(commentSelector == "Later" || commentSelector == "2 Month ago") && (
            <Box pl={4} pb={2}>
              <CommentThreadBox topComments={topComments} />
            </Box>
          )}
        </Box>
      </Stack> */}
      {/* <CommentPanel
        isOpenComment={isOpenComment && allowComment}
        postId={postId}
        triggeredFrom={triggeredFrom}
      /> */}
    </Box>
  );
};

export default VideoFeedCard;
