import request from "@/api/request";

export const getAllPartnerList = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/partner/list/all/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getExitingPartnerList = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/partner/list/existing/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const acceptPartnerRequest = async (payload, uid) => {
  const { senderId, receiverId } = payload;

  try {
    const res = await request({
      url: `/auth/v1/people/partner/accept/${senderId}/${receiverId}`,
      type: "PATCH",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addPartner = async (payload, uid) => {
  //  /feed/v1/group/comment
  const { senderId, receiverId } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/partner/send/${senderId}/${receiverId}`,
      type: "POST",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const cancelPartner = async (payload, uid) => {
  //  /feed/v1/group/comment

  const { senderId, receiverId } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/partner/cancel/${senderId}/${receiverId}`,
      type: "DELETE",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const rejectRequest = async (payload, uid) => {
  const { senderId, receiverId } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/partner/reject/${senderId}/${receiverId}`,
      type: "PATCH",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const removePartner = async (payload, uid) => {
  //  /feed/v1/group/comment

  const { senderId, receiverId } = payload;
  try {
    const res = await request({
      url: `/auth/v1/people/partner/remove/${senderId}/${receiverId}`,
      type: "DELETE",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
