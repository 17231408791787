import request from "@/api/request";

//getUpcomingEvents
export const getUpcomingEvents = async () => {
  try {
    const res = await request({
      url: `/events/v1/event/list/home`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//getPeopleSuggestions
export const getPeopleSuggestions = async (uid) => {
  try {
    const res = await request({
      url: `/auth/v1/people/friend/suggested/lists/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

//getVideoSuggestions
export const getVideoSuggestions = async () => {
  try {
    const res = await request({
      url: `/exams/v1/videoBank/suggestions`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getLiveEventToken = async ({
  eventChannelSequence,
  userSequence,
  joinType,
  _id,
}) => {
  try {
    const res = await request({
      url: `/events/v1/event/join/${_id}`,
      type: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userSequence: userSequence,
        eventChannelSequence: eventChannelSequence,
        duration: 864000,
        role: joinType,
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getLiveEventComments = async ({ sourceId, commentType }) => {
  try {
    const res = await request({
      url: `/events/v1/class/comment/list/${sourceId}/${commentType}/1/10`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getLiveEventCommentsReply = async (commentId) => {
  console.log("check live", commentId);
  try {
    const res = await request({
      url: `/events/v1/class/comment/reply/list/${commentId}/1/10`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const postLiveEventComments = async (payload) => {
  try {
    const res = await request({
      url: `/events/v1/class/comment`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
      type: "POST",
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const postLiveEventCommentsReplies = async (payload) => {
  try {
    const res = await request({
      url: `/events/v1/class/comment/reply`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
      type: "POST",
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getLiveEventImpComments = async ({ sourceId }) => {
  try {
    const res = await request({
      url: `/events/v1/class/comment/list/important/${sourceId}/event/1/10`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
