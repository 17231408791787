import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Image,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useInfiniteQuery } from "react-query";
import {
  Dot,
  MessageCircle,
  MoreHorizontal,
  MoreVertical,
  ChevronDownIcon,
  Share2,
  ThumbsUp,
} from "lucide-react";
import CommentSlider from "@/components/postCards/commentSlider/commentSlider";
import { ReactionPanel } from "@/components/common/reactionPanel";
import CommentThreadBox from "./commentThreadBox";
import { CommentPanel } from "@/components/common/commentPanel"; // Make sure the import path is correct
import { getUserPostComments } from "@/api/feed/user/comments";
import { useInView } from "react-intersection-observer";

const FeedCardFooter = ({
  topComments,
  postId,
  userReaction,
  triggeredFrom,
  isOpenComment,
  onToggleComment,
  createdyById,
  allowComment,
}) => {
  const { _id: uid } = useSelector((state) => state.userData);
  const { _id: groupId } = useSelector((state) => state.groupData);
  const [commentSelector, setcommentSelector] = useState("Top Comments");
  const [comment, setComment] = useState("");

  const {
    data: commentData,
    error,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [
      triggeredFrom == "group"
        ? "getGroupPostsComments"
        : "getAllPostsComments",
      triggeredFrom == "group" ? groupId : postId,
    ],
    queryFn: ({ pageParam = 1, limit = 10 }) =>
      triggeredFrom == "group" ? "" : getUserPostComments(postId, pageParam, 9),

    getNextPageParam: (lastPage, pages) => {
      const nextPage = lastPage?.data?.data?.pagination?.page + 1;
      return nextPage <= lastPage?.data?.data?.pagination?.totalPages
        ? nextPage
        : undefined;
    },
    onError: (error, variables, context) => {},
    onSuccess: (res) => {
      console.log("res comment", res);
    },
  });

  // Implement scrolling detection
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);
  return (
    <Stack paddingX={4}>
      <Divider />
      <ReactionPanel
        postId={postId}
        userReaction={userReaction}
        isOpenComment={isOpenComment}
        onToggleComment={onToggleComment}
        triggeredFrom={triggeredFrom}
      />
      {topComments?.length > 0 && <Divider />}

      {topComments?.length > 0 && (
        <Box py="1" zIndex="8000" position="static">
          <Menu>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              _hover={{}}
              _expanded={{ borderColor: "black" }}
            >
              <HStack>
                <span style={{ fontSize: "14px" }}>{commentSelector}</span>{" "}
                <ChevronDownIcon size="14px" />
              </HStack>
            </MenuButton>
            <MenuList zIndex="20">
              <MenuItem
                onClick={() => {
                  setcommentSelector("Top Comments");
                }}
              >
                Top Comments
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setcommentSelector("Later");
                }}
              >
                Latest
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setcommentSelector("2 Month ago");
                }}
              >
                2 Month ago
              </MenuItem>
              <MenuDivider />
            </MenuList>
          </Menu>
        </Box>
      )}
      <Box p="0">
        {commentSelector == "Top Comments" && topComments?.length > 0 && (
          <CommentSlider
            topComments={topComments}
            postId={postId}
            isOpenComment={true}
            appType="UserFeed"
            createdyById={createdyById}
            onToggleComment={onToggleComment}
            triggeredFrom={triggeredFrom}
            allowComment={allowComment}
            comment={comment}
            setComment={setComment}
          />
        )}
        {(commentSelector == "Later" || commentSelector == "2 Month ago") &&
          topComments?.length && (
            <Box pb={2}>
              <CommentThreadBox
                topComments={commentData.pages[0]}
                commentRef={ref}
                postId={postId}
                userReaction={userReaction}
                isOpenComment={isOpenComment}
                onToggleComment={onToggleComment}
                triggeredFrom={triggeredFrom}
                comment={comment}
                setComment={setComment}
              />
            </Box>
          )}
      </Box>

      <CommentPanel
        isOpenComment={true}
        postId={postId}
        triggeredFrom={triggeredFrom}
        comment={comment}
        setComment={setComment}
        allowComment={allowComment}
      />
    </Stack>
  );
};

export default FeedCardFooter;
