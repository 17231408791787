import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Image,
  Stack,
  TableContainer,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  Dot,
  MessageCircle,
  MoreVertical,
  ChevronDownIcon,
  ThumbsUp,
} from "lucide-react";
import React, { useState } from "react";
import LikeEmojiGroup from "../../common/likeEmojiGroup";
import CommentSlider from "../../postCards/commentSlider/commentSlider";
import AvatarGroups from "../../common/avatarGroups";
import { ReactionPanel } from "../../common/reactionPanel";
import { getTimeAgo } from "@/utilities/utilityFunction";
import { useDisclosure } from "@chakra-ui/react";
import { Carousel } from "antd";
import { useRouter } from "next/router";
import { CommentPanel } from "@/components/common/commentPanel";
import CommentThreadBox from "./commentThreadBox";
import PostOption from "@/components/common/postOption";

const DebateFeedCard = ({
  name,
  uid,
  profilePic,
  title,
  options,
  reactionCount,
  commentCount,
  createdAt,
  postId,
  followingCount,
  participants,
  userReaction,
  media,
  reactionCountDetail,
  triggeredFrom,
  topComments,
  allowComment,
}) => {
  const router = useRouter();
  const [commentSelector, setcommentSelector] = useState("Top Comments");
  const {
    isOpen: isOpenComment,
    onToggle: onToggleComment,
    onClose: onClose,
  } = useDisclosure();

  return (
    <Box bg="#fff" mt="1">
      <HStack
        align="center"
        justifyContent="space-between"
        padding={["3", null, "4"]}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: ["auto", null, "280px"],
          }}
        >
          <Box boxSize="45px">
            <Image
              objectFit="cover"
              width="100%"
              height="100%"
              className="rounded-md"
              src={profilePic}
              alt="Profile Image"
            />
          </Box>
          <Box ml="2">
            <p
              style={{ fontSize: "18px", color: "#171717", fontWeight: "600" }}
            >
              {name}
            </p>
            <p style={{ fontSize: "14px", color: "#636363" }}>
              {getTimeAgo(createdAt)}
            </p>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="4">
          <PostOption
            postUserId={uid}
            postId={postId}
            title={title}
            triggeredFrom={triggeredFrom}
            allowComment={allowComment}
          />
        </Box>
      </HStack>
      <Stack padding={["1", null, "1"]} paddingBottom={["3", null, "0"]}>
        <Box
          p="2"
          onClick={() => {
            router.push(`/feed/post/${postId}?triggeredFrom=${triggeredFrom}`);
          }}
        >
          <Box
            bg="brand.900"
            color="white.900"
            p="6"
            roundedTop="2xl"
            position="relative"
            overflow="hidden"
          >
            <Box position="absolute" top="0" right="0">
              <Image alt="vector img" src="/Vector103.svg" />
            </Box>
            <Tag>Debate</Tag>
            <Text fontSize="22px" fontWeight="500" pt="4" maxW="350px">
              {title}
            </Text>
          </Box>

          <Box>
            <Carousel>
              {media?.map((da, i) => (
                <Box position="relative" key={i} width="100%" height="40vh">
                  <Image
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    src={da}
                    alt={`Image ${i + 1}`}
                  />
                  <Text
                    color="white"
                    bg="#000"
                    position="absolute"
                    top="2"
                    right="2"
                    px="2"
                    rounded="full"
                  >
                    {i + 1}/{media.length}
                  </Text>
                </Box>
              ))}
            </Carousel>
          </Box>

          <Box bg="#F4F3FE" p="4" roundedBottom="2xl">
            <HStack>
              <Text fontSize="16px" color="#000000" fontWeight="400">
                Participants
              </Text>
              <AvatarGroup max={3} size="sm" gap="0">
                {participants?.map((item, ind) => (
                  <Avatar key={ind} name={item.name} src={item?.profilePic} />
                ))}
              </AvatarGroup>
            </HStack>
          </Box>
        </Box>
        <HStack align="center" fontWeight="400" fontSize="14px" padding="4">
          <Box display="flex" alignItems="center">
            <LikeEmojiGroup
              userReaction={userReaction}
              reactionCountDetail={reactionCountDetail}
            />
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#455564",
                paddingLeft: "5px",
              }}
            >
              {reactionCount}
            </span>
          </Box>
          <Box pl="5">
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#455564" }}
            >
              {commentCount}
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#8D96A5",
                paddingLeft: "5px",
              }}
            >
              comments
            </span>
          </Box>
          <Dot color="#8D96A5" />
          <Box>
            <span
              style={{ fontSize: "14px", fontWeight: "600", color: "#455564" }}
            >
              {followingCount}
            </span>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#8D96A5",
                paddingLeft: "5px",
              }}
            >
              following
            </span>
          </Box>
        </HStack>
      </Stack>
      {/* <Divider />
      <ReactionPanel
        postId={postId}
        userReaction={userReaction}
        isOpenComment={isOpenComment}
        onToggleComment={onToggleComment}
      />
      <Divider />

      <Box px="4" py="1" zIndex="8000" position="static">
        <Menu>
          <MenuButton
            px={4}
            py={2}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="1px"
            _hover={{}}
            _expanded={{ borderColor: "black" }}
          >
            <HStack>
              <span style={{ fontSize: "14px" }}>{commentSelector}</span>{" "}
              <ChevronDownIcon size="14px" />
            </HStack>
          </MenuButton>
          <MenuList zIndex="20">
            <MenuItem
              onClick={() => {
                setcommentSelector("Top Comments");
              }}
            >
              Top Comments
            </MenuItem>
            <MenuItem
              onClick={() => {
                setcommentSelector("Later");
              }}
            >
              Latest
            </MenuItem>
            <MenuItem
              onClick={() => {
                setcommentSelector("2 Month ago");
              }}
            >
              2 Month ago
            </MenuItem>
            <MenuDivider />
          </MenuList>
        </Menu>
      </Box>
      <Box p="0">
        {commentSelector == "Top Comments" && (
          <CommentSlider
            topComments={topComments}
            postId={postId}
            isOpenComment={true}
          />
        )}
        {(commentSelector == "Later" || commentSelector == "2 Month ago") && (
          <Box pl={4} pb={2}>
            <CommentThreadBox topComments={topComments} />
          </Box>
        )}
      </Box>
      <CommentPanel
        isOpenComment={isOpenComment && allowComment}
        postId={postId}
        triggeredFrom={triggeredFrom} 
      />*/}
    </Box>
  );
};

export default DebateFeedCard;
