import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import VideoCard from "./videoCard";
import { useQuery } from "react-query";
import { getVideoSuggestions } from "@/api/events";

const LatestVideosSlider = () => {
  const {
    isLoading: isLoading,
    data: data,
    isError: isError,
    error: error,
    isPending: isPending,
    isSuccess: isSuccess,
  } = useQuery({
    queryKey: ["getVideoSuggestion"],
    queryFn: () => getVideoSuggestions(),
    onError: (error, variables, context) => {
      console.log(error);
    },
    onSuccess: (res) => {
      console.log("home video data", res?.data?.data?.videoData);
    },
  });

  return (
    <Box bg="white.900" mt="1" p="4">
      <Text fontSize="18px" fontWeight="500" py="4">
        Latest Video
      </Text>
      <Flex
        overflowX="scroll"
        gap="6"
        css={{ scrollbarWidth: "thin", scrollbarColor: "#888 #f5f5f5" }}
        sx={{
          "-webkit-overflow-scrolling": "touch",
          scrollBehavior: "smooth",
        }}
      >
        {data?.data?.data?.videoData.map((item, i) => (
          <VideoCard key={i} data={item} />
        ))}
      </Flex>
    </Box>
  );
};

export default LatestVideosSlider;
