import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Image,
  Text,
  Flex,
  IconButton,
  Center,
  useDisclosure,
} from "@chakra-ui/react";
import { Play, PlayCircle } from "lucide-react";
import VideoModal from "./videoModal";

const VideoCard = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <VideoModal isOpen={isOpen} onClose={onClose} media={data?.media} />
      <Card minW="400px" rounded="2xl" overflow="hidden" position="relative">
        <CardBody padding="0" objectFit="cover" overflow="hidden">
          <Box
            height="200px"
            position="relative"
            onClick={onOpen}
            cursor="pointer"
          >
            <Image fit="contain" src="/reel1.jpg" />
            <Center
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              pointerEvents="none"
            >
              <Flex bg="rgba(0, 0, 0, 0.5)" borderRadius="50%" padding="4">
                <IconButton
                  bg="none"
                  icon={<PlayCircle size="lg" />}
                  color="white"
                />
              </Flex>
            </Center>
          </Box>
        </CardBody>
        <CardFooter display="block" bg="#F1F2F6">
          <Text fontSize="16px" fontWeight="600" py="2">
            {data?.title}
          </Text>
          <Text fontSize="14px" fontWeight="400" py="2">
            {data?.description}
          </Text>
        </CardFooter>
      </Card>
    </>
  );
};

export default VideoCard;
