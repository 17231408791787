import React, { useState } from "react";
import { Button, Box, Text, Image, Flex, Spinner } from "@chakra-ui/react";
import { Dot } from "lucide-react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import SkeletonLoader from "../skeletonLoader/skeletonLoader";
import { addFriend } from "@/api/people/friends";
import { addFollow } from "@/api/people/follow";
import { addPartner } from "@/api/people/studyPartner";

const useCreateMutation = (mutationFn, queryKey) => {
  const queryClient = useQueryClient();
  const { _id: uid } = useSelector((state) => state.userData);

  return useMutation({
    mutationFn,
    onError: () => {},
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKey, uid],
        exact: true,
      });
      toast.info("Request has been sent", {
        position: toast.POSITION.TOP_RIGHT,
      });
    },
    onSettled: () => {},
  });
};

const SuggestionSection = ({ friendSuggestions, triggeredFrom, isLoading }) => {
  console.log("suggestion data hc", friendSuggestions);

  const { _id: uid } = useSelector((state) => state.userData);
  const router = useRouter();

  const addFriendMutation = useCreateMutation(
    (payload) => addFriend(payload, uid),
    "getFriendList"
  );

  const followMutation = useCreateMutation(addFollow, "getAllFollowList");

  const addStudyPartnerMutation = useCreateMutation(
    addPartner,
    "getAllPartnerList"
  );

  const handleButtonClick = (suggestion, setLoading) => {
    setLoading(true);
    const mutation =
      triggeredFrom === "follow"
        ? followMutation
        : triggeredFrom === "partner"
        ? addStudyPartnerMutation
        : addFriendMutation;

    const payload =
      triggeredFrom === "follow"
        ? { followedBy: suggestion._id, followedTo: uid }
        : triggeredFrom === "partner"
        ? { senderId: uid, receiverId: suggestion._id }
        : { uid, friendId: suggestion._id };

    mutation.mutate(payload, {
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  return (
    <section>
      <div className="bg-white w-full mx-auto" style={{ borderRadius: "9px" }}>
        <div className="p-5 text-md font-semibold">
          <p>Suggested people to learn from</p>
        </div>
        <div
          className="flex flex-col bg-white p-auto"
          style={{ borderRadius: "9px" }}
        >
          <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
            <div className="flex flex-nowrap">
              {isLoading
                ? [...Array(5)].map((_, i) => (
                    <SkeletonLoader key={i} type="friendSuggestions" />
                  ))
                : friendSuggestions?.map((suggestion, i) => (
                    <SuggestionCard
                      key={i}
                      suggestion={suggestion}
                      triggeredFrom={triggeredFrom}
                      handleButtonClick={handleButtonClick}
                      router={router}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SuggestionCard = ({
  suggestion,
  triggeredFrom,
  handleButtonClick,
  router,
}) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Box className="px-3 relative justify-between" cursor="pointer">
      <Box
        rounded="3xl"
        className="w-52 h-72 max-w-xs relative overflow-hidden shadow-md bg-cover bg-center hover:shadow-xl transition-shadow duration-300 ease-in-out"
        backgroundImage={`url(${suggestion?.profilePic})`}
      >
        <Box
          display={suggestion.accountType === "public" ? "none" : "block"}
          position="absolute"
          right="3"
          top="3"
        >
          <Image src="/setting_icon.svg" alt="setting icon" />
        </Box>
        <div
          onClick={() =>
            router.push({ pathname: `/people/profile/${suggestion._id}` })
          }
          style={{
            position: "absolute",
            top: "40%",
            right: "0",
            bottom: "0",
            left: "0",
            background: "linear-gradient(to bottom, #ffffff 0%, #000000 60%)",
            zIndex: "0",
            mixBlendMode: "multiply",
          }}
        ></div>
        <Box className="text-center" mt="80%">
          <Text className="font-semibold opacity-90 text-white">
            {suggestion.name || "NA"}
          </Text>
          <Flex color="#fff" gap="0" align="center" justify="center">
            {suggestion?.exams?.slice(0, 2).map((exam, index) => (
              <React.Fragment key={index}>
                <Text fontSize="12px" color="#fff" zIndex={9}>
                  {exam}
                </Text>
                {index < 1 && (
                  <Dot
                    style={{ fontColor: "#fff", zIndex: "9" }}
                    color="#fff"
                  />
                )}
              </React.Fragment>
            ))}
          </Flex>
          <Box px="4" rounded="2xl" py="4">
            <Button
              isLoading={isLoading}
              bg="#B4B4B45C"
              color="white.900"
              fontSize="sm"
              _hover={{ bg: "white.900", color: "black.900" }}
              width="full"
              onClick={() => handleButtonClick(suggestion, setLoading)}
            >
              {triggeredFrom === "follow"
                ? "Follow"
                : triggeredFrom === "partner"
                ? "Add Study Partner"
                : "Add Friend"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SuggestionSection;
